import { createContext, ReactNode, useState } from 'react';
import { VesselPicture } from './vessel-picture.model';
import { FormData } from './form/NewVesselPictureForm';
import { v4 as uuidv4 } from 'uuid';

export interface VesselPictureContextState {
  vesselPictures: VesselPicture[];
  addVesselPicture: ({}: FormData) => void;
  setAllVesselPictures: (vesselPictures: VesselPicture[]) => void;
  favorites: VesselPicture[];
  totalFavorites: number;
  addFavorite: (vesselPicture: VesselPicture) => void;
  removeFavorite: (vesselPictureId: string) => void;
  vesselPictureIsFavorite: (vesselPictureId: string) => boolean;
  setActiveTabIndex: (index: number) => void;
  fetchVesselPictures: () => VesselPicture[];
}

export const VesselPictureContext = createContext<VesselPictureContextState>(
  {} as VesselPictureContextState
);

interface VesselPictureContextProps {
  children: ReactNode;
  setActiveTabIndex: (index: number) => void;
}

export function VesselPictureContextProvider({
  children,
  setActiveTabIndex
}: VesselPictureContextProps) {
  const [vesselPictures, setVesselPictures] = useState<VesselPicture[]>([]);
  const [userFavorites, setUserFavorites] = useState<VesselPicture[]>([]);

  const addVesselPictureHandler = (vesselData: FormData): void => {
    const key = uuidv4();
    let imageUrl = '';
    if (vesselData.image) {
      imageUrl = URL.createObjectURL(vesselData.image[0]);
    }
    const newVesselPicture: VesselPicture = {
      id: key,
      image: imageUrl,
      description: vesselData.description,
      isFavorite: vesselData.isFavorite,
      artist: vesselData.artist,
      pictureDate: vesselData.pictureDate,
      title: vesselData.title,
      type: vesselData.type
    };

    setVesselPictures((prevVesselPictures) => {
      return prevVesselPictures.concat(newVesselPicture);
    });

    if (vesselData.isFavorite) {
      addFavoriteHandler(newVesselPicture);
    }
  };

  // set the array of vesel pictures in the context's state
  const setAllVesselPicturesHandler = (
    newVesselPictures: VesselPicture[]
  ): void => {
    setVesselPictures(newVesselPictures);
  };

  // set the favorite in the context's state
  const addFavoriteHandler = (vesselPicture: VesselPicture): void => {
    // gaurantees correct order of processing
    setUserFavorites((prevFavorites) => {
      return prevFavorites.concat(vesselPicture);
    });
  };

  const removeFavoriteHandler = (vesselPictureId: string): void => {
    setUserFavorites((prevFavorites) => {
      return prevFavorites.filter(
        (vesselPicutre) => vesselPicutre.id !== vesselPictureId
      );
    });
  };

  const vesselPictureIsFavoriteHandler = (vesselPictureId: string): boolean => {
    // if at least one item has the asked-for id
    return userFavorites.some(
      (vesselPicture) => vesselPicture.id === vesselPictureId
    );
  };

  const setActiveTabIndexHandler = (index: number): void => {
    setActiveTabIndex(index);
  };

  // meant to be the API call to get all the vessel picture data, and add to the context
  const fetchVesselPicturesHandler = (): VesselPicture[] => {
    // TODO: refactor where we are fetching from - long term goal
    fetch(
      'https://crowley-starter-default-rtdb.firebaseio.com/vessel-pictures.json',
      {}
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const tempVesselPictures: VesselPicture[] = [];

        // set the id and build the rest of the object from the data key
        for (const key in data) {
          const vesselPicture = { id: key, ...data[key] };
          tempVesselPictures.push(vesselPicture);
        }
        setVesselPictures(tempVesselPictures);
      });

    return vesselPictures;
  };

  const context = {
    vesselPictures: vesselPictures,
    addVesselPicture: addVesselPictureHandler,
    setAllVesselPictures: setAllVesselPicturesHandler,
    favorites: userFavorites,
    totalFavorites: userFavorites.length,
    addFavorite: addFavoriteHandler,
    removeFavorite: removeFavoriteHandler,
    vesselPictureIsFavorite: vesselPictureIsFavoriteHandler,
    setActiveTabIndex: setActiveTabIndexHandler,
    fetchVesselPictures: fetchVesselPicturesHandler
  };

  return (
    <VesselPictureContext.Provider value={context}>
      {children}
    </VesselPictureContext.Provider>
  );
}

export default VesselPictureContext;
