import {
  Drawer,
  IconButton,
  TabNavigation
} from '@org-crowley/enterprise-react-component-library';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import { StarterSidebarContent } from './StarterSidebarContent';
import { VesselPictureContextProvider } from './vessel-pictures/VesselPictureContext';
import { MdInfo } from 'react-icons/md';

export interface NavTab {
  displayName: string;
  pathString: string;
}

export interface LayoutContentsProps {
  children: ReactNode | ReactNode[];
}

export function LayoutContents({ children }: LayoutContentsProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const router = useRouter();
  const TABS: NavTab[] = [
    {
      displayName: 'Home',
      pathString: '/'
    },
    { displayName: 'Vessel Pictures', pathString: '/vessel-pictures/' },
    { displayName: 'Cities', pathString: 'cities/' }
  ];

  const onSidebarClose = () => setSidebarIsOpen(false);

  return (
    <>
      <TabNavigation
        className="px-9"
        tabs={TABS.map((tab) => tab.displayName)}
        onChange={(clickedTabIndex) => {
          router.push({
            pathname: TABS[clickedTabIndex].pathString,
            query: { ...router.query }
          });
          setActiveTabIndex(clickedTabIndex);
        }}
        activeTabIndex={activeTabIndex}
      />
      <Drawer isOpen={sidebarIsOpen} onClose={onSidebarClose}>
        <StarterSidebarContent
          onSidebarContentClose={onSidebarClose}
        ></StarterSidebarContent>
      </Drawer>
      <IconButton
        onClick={() => setSidebarIsOpen(true)}
        Icon={MdInfo}
        customBackground={true}
        className="fixed top-[13rem] sm:top-80 right-[-4px] bg-blue-5 hover:bg-blue-10 text-silver-40 hover:text-silver-50 drop-shadow-md"
      />
      {/* children should be <Component from app.tsx */}
      <VesselPictureContextProvider setActiveTabIndex={setActiveTabIndex}>
        {children}
      </VesselPictureContextProvider>
    </>
  );
}
