import {
  Button,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  Typography
} from '@org-crowley/enterprise-react-component-library';

interface Props {
  onSidebarContentClose: () => void;
}

export function StarterSidebarContent({ onSidebarContentClose }: Props) {
  return (
    <Sidebar>
      <SidebarHeader className="flex justify-between">
        <Typography variant="h600">Referential Architecture</Typography>
      </SidebarHeader>
      <SidebarContent className="p-10">
        <Typography as="div" variant="body-large">
          Purpose and Explanation
        </Typography>
        <Typography
          as="div"
          variant="body-small"
          className=" text-[#58C1CD] italic bg-[#471F5F] p-8 m-4 text-center"
        >
          This web application is meant as a reference and a starting place for
          front-end developers. It is not comprehensive, but should have
          implementations of common patterns. The intent is to add common
          features we find requested.
        </Typography>
        <Typography
          as="div"
          variant="body-medium"
          className="selection:text-[#471F5F] selection:bg-[#58C1CD]"
        >
          <p className="pb-2">
            You should find in this web app a form that takes input from the
            user, including specific file types, and adds them locally to an
            existing data set provided by a backend. You will also find the
            basics of navigation and routing in the NextJS framework, and the
            common structure for project setup.
          </p>
          <p className="pb-2">
            If you are here as a developer, hopefully this will provide examples
            of what you are looking to create, and the various capabilities of
            the chosen tech stack on the front-end. Feel free to delete, add,
            change, and manipulate as needed for the specific needs of your
            business.
          </p>
          <p className="pb-2">
            If you are just a visitor, please, click around, and see if anything
            sparks interest or ideas for what you may wish to do in your own
            organization.
          </p>
          <p className="pb-2 text-h200 italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            tincidunt vitae eros sed ultricies. Nulla tempus nisi dui, at
            fringilla orci bibendum at. Sed pretium ante ante, auctor vulputate
            metus lacinia a. Fusce fermentum augue et condimentum mattis.
            Vestibulum et velit rhoncus, dictum justo eu, posuere lacus. Aliquam
            erat volutpat. Ut in turpis ipsum. Curabitur vitae lorem vel dui
            consequat pulvinar nec ac lectus. Nunc at scelerisque diam. Praesent
            malesuada tortor sem. Sed imperdiet sapien lorem, et ornare justo
            vestibulum quis. Integer placerat convallis aliquet. Curabitur
            facilisis euismod turpis et placerat.
          </p>
          <p className="pb-2 text-h200 italic">
            Donec posuere leo facilisis est pulvinar porta. Sed eget sem a risus
            gravida pulvinar. Quisque eu lorem quis lorem sagittis pellentesque.
            Suspendisse convallis scelerisque enim, a sollicitudin libero
            faucibus in. Maecenas quis consequat lectus. Phasellus urna nunc,
            ultricies pulvinar nisi sed, iaculis lacinia mauris. Nunc quis eros
            efficitur, hendrerit massa non, fringilla enim.
          </p>
        </Typography>
      </SidebarContent>
      <SidebarFooter className="flex-row-reverse">
        <Button variant="secondary" onClick={onSidebarContentClose}>
          Close me!
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
}
